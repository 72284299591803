import WishFlexBox from "./WishFlexBox";

const LoadingNote = ({ message }) => {
  return (
    // <WishFlexBox justifyContent="center">
    //   <span
    //     className="spinner-border spinner-border-sm"
    //     role="status"
    //     aria-hidden="true"
    //   ></span>
    //   <strong>&nbsp; {message ?? "Processing"}</strong>
    // </WishFlexBox>
    <div className="spinner-overlay">
      <div id="spinner-text">
        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <strong>&nbsp; {message ?? "Processing"}</strong>
      </div>
    </div>
  );
};

export default LoadingNote;
