import React, { useState } from "react";

const SkeletonLoader = ({ rows }) => {
  return (
    <div className="card is-loading">
      <div className="row">
        <div className="col-sm-12">
          <div className="content-1">
            {[...Array(rows)].map((_, index) => (
              <p key={index}></p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
