export const SERVER_FAILURE_RESPONSE = {
  status: (String, ""),
  message: (String, ""),
  data: {},
  error_type: (String, ""),
};

export const EMPTY_CREDENTIALS = "Invalid / Empty Credentials";

export const SUCCESS = "success";
export const ERROR = "error";

export const LOAD_EXTREME_LEFT = "load-extreme-left";
export const LOAD_EXTREME_RIGHT = "load-extreme-right";
export const LOAD_PREFERRED_LEFT = "load-prefered-extreme-left";
export const LOAD_PREFERRED_RIGHT = "load-prefered-extreme-right";
export const NOTIFICATIONS_LIMIT = 5;

export const NODE_POSITIONS = ["first", "second"];

export const AUTHENTICATION_MODES = {
  sign: 0,
  forgotPassword: 1,
  forgotPasswordSuccess: 2,
};
