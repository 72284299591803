import { useEffect } from "react";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function ListGroup({ items, onSelect, selectedItemIndex, label }) {
  const [selectedItem, setSelectedItem] = useState(selectedItemIndex ?? null);
  // const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    if (selectedItemIndex !== selectedItem) setSelectedItem(selectedItemIndex);
  }, [selectedItemIndex]);

  return (
    <div>
      <div className="list-group" id={uuidv4()}>
        {items.map((item, index) => (
          <button
            type="button"
            key={index}
            aria-current="true"
            className={
              "list-group-item list-group-item-action" +
              (selectedItem === index ? " active " : " ")
            }
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(index);
              onSelect && onSelect(item);
            }}
          >
            {item[label]}
          </button>
        ))}
      </div>
{/* 
      <div className="list-group file-list" id={uuidv4()}>
        {items.map((item, index) => {
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            className={
              "d-flex list-group-item list-group-item-action " +
              (selectedItem === index ? "active " : " ") +
              (filterText !== null && item.includes(filterText)
                ? ""
                : filterText === null
                ? ""
                : "hidden")
            }
            aria-current="true"
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedItem(index);
              onSelect && onSelect(index);
            }}
          >
            <span className="mr-auto">{item.post_name}</span>
          </a>;
        })}
      </div> */}
    </div>
  );
}
