import React from 'react';

const MaintenanceMode = () => {
  return (
    <div className="maintenance-container">
      <div className="maintenance-content">
        <h1 className="maintenance-heading">Under Maintenance</h1>
        <p className="maintenance-text">
          We are currently performing maintenance on the app.
          Please check back later.
        </p>
      </div>
    </div>
  );
};

export default MaintenanceMode;
