import React, { Component } from "react";
import { Link } from "react-router-dom";

export default function WishCard({
  textWhite,
  bgColor,
  linkToTitle,
  linkToIcon,
  icon,
  title,
  line1,
  line2,
  linkTo,
  linkToState,
  linkToModal,
  modalDisableKeyboard,
  modalStaticBackground,
  handleClick,
  maxHeight,
}) {
  const cardTextColor = textWhite === true ? " text-white " : "";
  const cardBackground =
    bgColor === "" || bgColor === undefined
      ? " box-shadow-0 onhover-shadow onhover-change-border"
      : " box-shadow-3 onhover-change-border " + bgColor + " " + cardTextColor;
  const cardLinkTitle = linkToTitle === "" || linkToTitle === undefined ? "PROCEED" : linkToTitle.toUpperCase();
  const cardLinkIcon = linkToIcon === "" || linkToIcon === undefined ? "la-angle-right" : linkToIcon;
  const cardIcon = icon === "" || icon === undefined ? "" : icon;

  return (
    <div className={"card" + cardBackground} style={{ height: maxHeight ? "90%" : "auto" }}>
      <div className="card-content collapse show">
        <div className="card-body row">
          <div className="col-10">
            <h4 className={"card-title " + cardTextColor}>{title}</h4>
          </div>
          <div className="col-2 text-right">
            <i className={"la " + cardIcon}></i>
          </div>

          <div className="col-12">
            <p className="card-text" key="{line}">
              {line1} <br />
              {line2}
            </p>
          </div>
        </div>
        <div className="card-footer border-top-lighten-5 text-right mt-auto">
          <Link
            to={linkTo === undefined ? "" : linkTo}
            onClick={handleClick && handleClick}
            state={linkToState ?? {}}
            className={"card-link " + cardTextColor}
            data-toggle="modal"
            data-target={linkToModal ?? ""}
            data-keyboard={modalDisableKeyboard ?? "true"}
            data-backdrop={modalStaticBackground ?? "non-static"}
          >
            {cardLinkTitle}
            <i className={"la " + cardLinkIcon}></i>
          </Link>
        </div>
      </div>
    </div>
  );
}
